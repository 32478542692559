export const LOGIN_API = 'auth/login';
export const CHANGE_PASSWORD_API = 'auth/change-password';
export const FORGOT_PASSWORD_API = 'auth/forgot-password';

// users
export const USERS_LIST_API = 'user/list';
export const USER_DETAILS_API = 'user/details';
export const USER_STATUS_API = 'user/change-status';
export const USER_DELETE_API = 'user/delete';
export const USER_PRODUCTS_LIST_API = 'product/user-products';
export const USER_BUYING_PRODUCTS_API = 'product/user-buying-products';
export const USER_PET_LIST_API = 'user-pet/list';
export const USER_REVIEWS_LIST_API = 'review/user-reviews';
export const USER_EDIT_API = 'user/edit';
export const USER_KYC_API = 'user/kyc-verify';
export const USER_PRODUCT_STATUS_API = 'user/products-status';

// decks
export const DECK_LIST_API = 'deck/list';
export const CREATE_DECK_API = 'deck/add';
export const UPDATE_DECK_API = 'deck/update';
export const DECK_IMAGE_API = 'deck/image-upload';
export const DELETE_IMAGE_API = 'deck/image-delete'
export const DECK_DETAILS_API = 'deck/details';
export const DECK_DELETE_API = 'deck/delete';
export const DECK_STATUS_API = 'deck/status';
// decks cards
export const CARD_LIST_API = 'deck/card-list';
export const NORMAL_CARD_LIST_API = 'deck/normal-card-list';
export const CREATE_CARD_API = 'deck/add-card';
export const UPDATE_CARD_API = 'deck/edit-card';
export const CARD_DETAILS_API = 'deck/card-details';
export const CARD_DELETE_API = 'deck/delete-card';

//game points

export const POINT_LIST_API = 'game-point/list';
export const UPDATE_POINT_API = 'game-point/update';
export const ADD_POINT_API = 'game-point/add';
export const DELETE_POINT_API = 'game-point/delete';

//game time
export const TIME_LIST_API = 'game-time/list';
export const UPDATE_TIME_API = 'game-time/update';
export const ADD_TIME_API = 'game-time/add';
export const DELETE_TIME_API = 'game-time/delete';

//categories
export const CATEGORY_LIST_API = 'category/list'
export const CATEGORY_CHANGE_STATUS_API = 'category/change-status'
export const CATEGORY_DELETE_API = 'category/delete';
export const CATEGORY_DETAILS_API = 'category/details';
export const UPDATE_CATEGORY_API = 'category/edit'
export const ADD_CATEGORY_API = 'category/save'
export const SUBCATEGORY_LIST_API = 'sub-category/list'
export const SUBCATEGORY_DELETE_API = 'sub-category/delete';
export const SUBCATEGORY_CHANGE_STATUS_API = 'sub-category/change-status'
export const SECTION_CHANGE_STATUS_API = 'section/change-status'
export const SECTION_DELETE_API = 'section/delete';
export const UPDATE_SUBCATEGORY_API = 'sub-category/edit'
export const ADD_SUBCATEGORY_API = 'sub-category/save'
export const UPDATE_SECTION_API = 'section/edit'
export const ADD_SECTION_API = 'section/save'
export const SECTION_DETAILS_API = 'section/details';
export const SUBCATEGORY_DETAILS_API = 'sub-category/details';
export const SECTIONS_LIST_API = 'section/list'
export const ARTICLE_LIST_API = 'article/list'
export const ARTICLE_ADD_API = 'article/save'
export const ARTICLE_EDIT_API = 'article/edit'
export const ARTICLE_CHANGE_STATUS_API = 'article/change-status'
export const ARTICLE_DELETE_API = 'article/delete'
export const BRAND_LIST_API = 'brand/list'
export const BRAND_ADD_API = 'brand/save'
export const BRAND_EDIT_API = 'brand/edit'
export const BRAND_CHANGE_STATUS_API = 'brand/change-status'
export const BRAND_DELETE_API = 'brand/delete'

//Product
export const PRODUCT_LIST_API = 'product/list'
export const PRODUCT_DETAIL_API = 'product/details';
export const PRODUCT_CHANGE_STATUS_API = 'product/change-status';
export const PRODUCT_CHANGE_PRIORITY = 'product/change-priority';
export const PRODUCT_APPROVE_REJECT_API = 'product/approve-reject';
export const PRODUCT_SOLD_API = 'product/sold';
export const PRODUCT_CHANGE_WEIGHT_API = 'product/change-weight';

export const PRODUCT_OFFERS_API = 'offer/list';

//Privacy
export const PRIVACY_ADD_API = 'cms/privacy-save';
export const PRIVACY_DETAIL_API = 'cms/get-privacy-policy';

export const FAQ_LIST_API = 'faq/list';
export const FAQ_ADD_API = 'faq/save';
export const FAQ_EDIT_API = 'faq/edit';
export const FAQ_CHANGE_STATUS_API = 'faq/change-status';
export const FAQ_DELETED_API = 'faq/delete';

export const SOCIAL_LINK_DETAILS = 'social-links/get-social-links';
export const SOCIAL_LINK_SAVE = 'social-links/social-links-save';

export const ABOUT_US_SAVE = 'about-us/about-us-save';
export const ABOUT_US_GET = 'about-us/get-about-us';


export const TERMS_CONDITION_GET = 'terms/get-terms-conditions';
export const TERMS_CONDITION_SAVE = 'terms/terms-conditions-save';


export const GDPR_GET = 'gdpr/get';
export const GDPR_SAVE = 'gdpr/save';


/**ORDER MANAGEMENT */

export const ORDERS_LIST_API = 'order/list';
export const ORDER_DETAILS_API = 'order/details';
export const ORDER = 'order';
export const PARCEL_TRACKING = 'sendcloud/parcel-tracking'

export const BLOG_LIST_API = 'tips-blog/list';
export const BLOG_SAVE_API = 'tips-blog/save';
export const BLOG_DELETE_API = 'tips-blog/delete';
export const BLOG_DETAILS_API = 'tips-blog/details';
export const BLOG_EDIT_API = 'tips-blog/update';
export const BLOG_UPLOAD_IMAGE_API = 'tips-blog/upload-image';

export const GET_COMMISION = 'commission/get';
export const SAVE_COMMISION = 'commission/update';
export const GET_ALL_COMMISION = 'commission/getAll';

export const GET_REVIEW = 'review/list';
export const DELETE_REVIEW = 'review/delete';

//Dashboard
export const GET_PRODUCT_REPORT = 'dashboard/get-product-report';
export const GET_GRAPH_STATS = 'dashboard/stats';
export const GET_FAV_PRODUCT = 'dashboard/get-fav-product';
export const GET_REVIEW_PRODUCT = 'dashboard/get-reviews-percentage';
export const GET_PROFIT_STATS = 'dashboard/profit/stats'

//Report Management
export const GET_REPORT_STATS = 'report/stats';

/* Transaction Routes */


export const TRANSACTION_LIST = 'transaction/list';


export const CHAT_LIST = 'chat/list';
