<div class="content">
    <h2>Change Password</h2>
    <mat-card>
      <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="standard">
          <mat-label>Current Password</mat-label>
          <input type="password" matInput placeholder="current password" formControlName="passwordCurrent" required>
          <mat-error> {{changePasswordForm.controls.passwordCurrent.errors|validateError:'Current Password'}} </mat-error>
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>New Password</mat-label>
          <input type="password" matInput placeholder="new password" formControlName="password" required>
          <mat-error> {{changePasswordForm.controls.password.errors|validateError:'New Password'}} </mat-error>
          <mat-error *ngIf="changePasswordForm.controls.password.errors?.pattern">
            Password should be contain a uppercase a lowercase a special character and a number
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Confirm Password</mat-label>
          <input type="password" matInput placeholder="confirm password" formControlName="passwordConfirm" required>
          <mat-error> Password not matched </mat-error>
        </mat-form-field>
        <br>
        <button type="submit" mat-raised-button color="primary" [disabled]="changePasswordForm.invalid"> Submit </button>
      </form>
    </mat-card>
  </div>
  