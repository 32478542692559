export const LOGIN_ROUTE = {
  path: 'login',
  get url(): string {
    return `/${this.path}`;
  },
};

export const FORGOT_PASSWORD_ROUTE = {
  path: 'forgot-password',
  get url(): string {
    return `/${this.path}`;
  },
};

export const CHANGE_PASSWORD_ROUTE = {
  path: 'change-password',
  get url(): string {
    return `/${this.path}`;
  },
};

export const DASHBOARD_ROUTE = {
  path: 'dashboard',
  get url(): string {
    return `/${this.path}`;
  },
};

export const USER_ROUTE = {
  path: 'users',
  get url(): string {
    return `/${this.path}`;
  },
};

export const CATEGORY_ROUTE = {
  path: 'category',
  get url(): string {
    return `/${this.path}`;
  },
};

export const CMS_ROUTE = {
  path: 'cms',
  get url(): string {
    return `/${this.path}`;
  },
};

export const BRAND_ROUTE = {
  path: 'brand',
  get url(): string {
    return `/${this.path}`;
  },
};

export const COMMISSION_ROUTE = {
  path: 'commision',
  get url(): string {
    return `/${this.path}`;
  },
};

export const REVIEW_ROUTE = {
  path: 'review',
  get url(): string {
    return `/${this.path}`;
  },
};

export const PRODUCT_ROUTE = {
  path: 'product',
  get url(): string {
    return `/${this.path}`;
  },
};

export const PROPERTY_ROUTE = {
  path: 'properties',
  get url(): string {
    return `/${this.path}`;
  },
};

export const DECK_ROUTE = {
  path: 'decks',
  get url(): string {
    return `/${this.path}`;
  },
};

export const TIME_ROUTE = {
  path: 'times',
  get url(): string {
    return `/${this.path}`;
  },
};
export const POINT_ROUTE = {
  path: 'points',
  get url(): string {
    return `/${this.path}`;
  },
};

export const ORDERS_ROUTE = {
  path: 'orders',
  get url(): string {
    return `/${this.path}`;
  },
};

export const REPORT_ROUTE = {
  path: 'report',
  get url(): string {
    return `/${this.path}`;
  },
};

export const TRANSACTION_ROUTE = {
  path: 'transaction',
  get url(): string {
    return `/${this.path}`;
  },
};

export const SHIPPING_FRANCE_ROUTE = {
  path: 'show',
  get url(): string {
    return `${COMMISSION_ROUTE.url}/${this.path}`;
  },
};

export const SHIPPING_BELGIUM_ROUTE = {
  path: 'edit-show',
  get url(): string {
    return `${COMMISSION_ROUTE.url}/${this.path}`;
  },
};

export const ASSOCIATION_ROUTE = {
  path: 'association',
  get url(): string {
    return `/${this.path}`;
  },
};
