import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import {
  ASSOCIATION_ROUTE,
  BRAND_ROUTE,
  CATEGORY_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  CMS_ROUTE,
  COMMISSION_ROUTE,
  DASHBOARD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  ORDERS_ROUTE,
  PRODUCT_ROUTE,
  REPORT_ROUTE,
  REVIEW_ROUTE,
  TRANSACTION_ROUTE,
  USER_ROUTE,
} from './constants/route.constants';
import { ContainerComponent } from './container/container.component';
import { AuthGuard } from './guards/auth.guard';
import { ProtectGuard } from './guards/protect.guard';

const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: DASHBOARD_ROUTE.path,
        pathMatch: 'full',
      },
      {
        path: DASHBOARD_ROUTE.path,
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: USER_ROUTE.path,
        loadChildren: () =>
          import('./pages/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: CATEGORY_ROUTE.path,
        loadChildren: () =>
          import('./pages/categories/categories.module').then(
            (m) => m.CategoriesModule
          ),
      },
      {
        path: CMS_ROUTE.path,
        loadChildren: () =>
          import('./pages/cms-management/cms-management.module').then(
            (m) => m.CmsManagementModule
          ),
      },
      {
        path: COMMISSION_ROUTE.path,
        loadChildren: () =>
          import('./pages/commission-mgt/commission-mgt.module').then(
            (m) => m.CommissionMgtModule
          ),
      },

      {
        path: REVIEW_ROUTE.path,
        loadChildren: () =>
          import('./pages/review/review.module').then((m) => m.ReviewModule),
      },

      {
        path: BRAND_ROUTE.path,
        loadChildren: () =>
          import('./pages/brand/brand.module').then((m) => m.BrandModule),
      },
      {
        path: PRODUCT_ROUTE.path,
        loadChildren: () =>
          import('./pages/listing/listing.module').then((m) => m.ListingModule),
      },
      {
        path: ORDERS_ROUTE.path,
        loadChildren: () =>
          import('./pages/orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: TRANSACTION_ROUTE.path,
        loadChildren: () =>
          import('./pages/transaction-mgt/transaction-mgt.module').then(
            (m) => m.TransactionMgtModule
          ),
      },

      {
        path: REPORT_ROUTE.path,
        loadChildren: () =>
          import('./pages/report-mgt/report-mgt.module').then(
            (m) => m.ReportMgtModule
          ),
      },
      {
        path: ASSOCIATION_ROUTE.path,
        loadChildren: () =>
          import('./pages/association/association.module').then(
            (m) => m.AssociationModule
          ),
      },
      {
        path: CHANGE_PASSWORD_ROUTE.path,
        component: ChangePasswordComponent,
      },
    ],
  },
  {
    path: LOGIN_ROUTE.path,
    component: LoginComponent,
    canActivate: [ProtectGuard],
  },
  {
    path: FORGOT_PASSWORD_ROUTE.path,
    component: ForgotPasswordComponent,
    canActivate: [ProtectGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
